<template>
  <div>
    <Snackbar ref="snackbar" />
    <v-dialog v-model="dialog" persistent width="650">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #F05326;"
        >
          <span class="white--text">
            Booking License
          </span>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog()" color="white" :disabled="process.run">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            flat
            disabled
            v-model="code"
            label="License Code"
            color="#F05326">
          </v-text-field>
          <v-autocomplete
            outlined
            dense
            flat
            v-model="form.company_id"
            :items="company"
            label="Choose Company"
            item-value="id"
            item-text="name"
            color="#F05326">
          </v-autocomplete>
          <v-text-field
            outlined
            dense
            flat
            v-model="form.fullname"
            label="Full Name"
            color="#F05326">
          </v-text-field>
          <v-text-field
            outlined
            dense
            flat
            v-model="form.email"
            label="Email"
            type="email"
            color="#F05326">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            outlined
            color="#EF6C00"
            class="text-capitalize"
            :loading="process.run"
            @click="closeDialog()">
            Batal
          </v-btn>
          <v-btn 
            depressed
            color="#EF6C00" 
            class="white--text text-capitalize"
            :loading="process.run"
            @click="Booking()">
            Booking License
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get, post } from '@/service/Axios'
import Snackbar from '@/components/Snackbar.vue'
export default {
  components: { Snackbar },
  props: {
    dialog: false,
    code: "",
  },
  data () {
    return {
      form : {
        code: "",
        company_id: "",
        fullname: "",
        email: "",
      },
      company: [],
      process: {
        run: false,
      }
    }
  },
  mounted () {
    this.fetchCompany()
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.form = {
        code: "",
        company_id: "",
        fullname: "",
        email: "",
      }
      this.$emit("close")
    },
    async fetchCompany(){
      this.process.run = true

      await get(`company/v1/superuser/list?limit=1000`).then(response => {
        let res = response.data
        this.company = res.data.list
        this.process.run = false
        this.$store.state.process.run = false
      })
    },
    async Booking() {
      this.process.run = true
      await post(`license/v1/superuser/booking`, {
        data: {
          code: this.code,
          company_id: this.form.company_id,
          email: this.form.email,
          fullname: this.form.fullname,
        }
      }).then(response => {
        let res = response.data
        if (res.status == 200) {
          this.$refs.snackbar.open("#4CAF50", `Berhasil Booking License`);
          this.$emit("close")
        } else {
          this.$refs.snackbar.open("#D32F2F", `Gagal Booking License. ${res.message}`);
          this.$emit("close")
        }
      }).catch(error => {
        console.log(error);
        this.dialog = false
        this.$emit("close")
      }).finally(() => {
        this.process.run = false
      })
    }
  }
}
</script>